.login-subscribe-container {
  padding: 50px 0 50px;
}

.login-title {
  font-size: 30px;
  margin: 0 0 30px;
  padding: 0 0 5px;
  text-align: center;
  border-bottom: 1px solid $line-color;
  @include breakpoint(small down) {
    font-size: 30px;
  }

    &.-small {
      font-size: 30px;
      color: $black;
    }
}

.login-link {
  @include brandon-bold;
  display: inline-block;
  text-transform: uppercase;
  font-size: 11px;
  letter-spacing: 0.1em;
}

.remember-me {
  margin-bottom: 16px;
}

//subscribe

.border-top-subscribe{
  border-top: 1px solid $dark-grey;
  padding-top: 20px;
}

//facebook icon

.fb-icon{
  margin-right: 20px;
}