// colors
$black: #2F2D32;
$black-hover: #191919;
$light-grey: #F7F7F7;
$dark-grey: #EAEAEA;
$dark-grey-second: #EFEFEF;
$dark-grey-third: #E6E6E8;
$grey-border: #565555;
$grey-paypal: #eee;
$violet: #9B93BE;
$light-violet:#C1BBDB;
$gold: #a5864d;
$gold-hover: #977D4E;
$white: #ffffff;
$blue-facebook: #1877f2;
$blue-facebook-hover: #1269db;
$line-color: #e3e6ea;
$red: red;
$text-grey: #EAEAEA;
$success: #1ea75a;
$box-shadow: rgba(34, 34, 34, 0.12);
$white: #ffffff;
$green-whatsapp:#3FC351;
// Slick slider
$slick-font-path: '/skins/opificio/fonts/slick/';
$slick-loader-path: '/skins/opificio/images/slick/';