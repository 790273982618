#cart-container {
    padding: 40px 0;
    background-color: $light-grey;

    @include breakpoint(small down) {
        padding-top: 0px;
    }
}
.cart-title {
    width: 100%;
    padding: 0 0 5px;
    border-bottom: 1px solid $line-color;

    @include breakpoint(small down) {
        margin-top: 16px;
    }

    h1, h2, h3, h4 {
        color: $black;
        font-size: 24px;
        line-height: 32px;
    }
}

.cart-product {
    background-color: $white;
    padding: 24px;
    border-radius: 8px;
    border: 1px solid $line-color;
    margin-bottom: 16px;
    @include breakpoint(medium down) {
        padding: 16px;
    }
    &.checkout {
        padding: 8px;
        .cart-main-price {
            margin-bottom: 0;
        }
    }
}

.cart-product__title {
    font-size: 20px;
    line-height: 32px;
    @include breakpoint(medium down) {
        margin-top: 20px;
    }
}

.cart-product__subtitle {
    font-size: 16px;
    display: block;
    margin: 2px 0;
    &.code {
        font-size: 14px;
        line-height: 24px;
    }
}

.btn-cart-save-for-later,
.btn-cart-remove {
    display: flex;
    align-items: center;
    font-size: 14px;
    line-height: 24px;

    img {
        width: 12px;
        height: 12px;
        margin-right: 6px;
    }
}

.cart-product__buttons {
    text-align: center;
    padding: 35px 0;

    button {
        @include brandon-bold;
        background-color: $black;
        border: 1px solid $black;
        color: white;
        width: 182px;
        text-transform: uppercase;
        font-size: 11px;
        letter-spacing: 0.1em;
        padding: 9px 0 7px;
        position: relative;
        margin: 0 5px;

        &.-light {
            color: $black;
            background-color: white;
        }

        &:active {
            top: 1px;
        }
    }
}

.cart-product__price-label {
    text-transform: uppercase;
    font-size: 11px;
    letter-spacing: 0.05em;
}

.cart-product__price-big {
    font-size: 22px;
    margin-top: -5px;
}

.cart-resume {
    background-color: $white;
    padding: 24px;
    border-radius: 8px;
}

.cart-resume__top {
    p {
        @include brandon-medium;
        font-size: 16px;
        line-height: 24px;
        text-align: left;
        margin-bottom: 8px;

        &.right {
            text-align: right;
        }

        &.-light {
            opacity: 0.7;
        }
        &.discount {
            color: $gold;
        }
    }

    &.-right {
        text-align: left;

        p {
            font-size: 16px;
            line-height: 24px;
        }
    }
}

.coupon-input {
    display: flex;
    align-items: center;

    > * {
        width: 50%;
    }

    input {
        margin: 0 10px 0 0;
        height: 40px;
        box-shadow: none;
        border-color: $line-color;
        text-align: center;
        font-weight: bold;
        letter-spacing: 0.04em;
        font-size: 14px;
        padding: 8px 8px 6px;
        color: $black;
    }
}

.coupon-label {
    font-size: 16px;
    margin-bottom: 4px;
    transition: all 0.15s ease-in-out;
    top: 6px;
    left: 14px;
    text-transform: uppercase;
    color: $black;
    font-size: 14px;
}

.country-select {
    @include brandon-bold;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    font-size: 12px;
    height: 34px;
    padding-left: 11px;
    border-color: $line-color;
}

.cart-total {
    margin: 16px 0;

    span {
        @include brandon-medium;
        display: block;
    }
}

.cart-total__label {
    font-size: 18px;
    line-height: 24px;
    text-align: left;
}

.cart-total__price {
    font-size: 18px;
    line-height: 24px;
    text-align: right;
}

.select-carrier {
    margin: 0 0 15px;
}

.burn-coupon-form {
    background-color: $light-grey;
}

.cart-resume__space {
    margin: 24px 0 24px 0;
}

.cart-dimension {
    font-size: 16px;
    margin: 2px 0;
}
.cart-update .qty-input-container {
    margin-top: 3px;
}

.description-product_cart {
    display: flex;
    align-items: center;
}

.description-product_checkout {
    display: flex;
    align-items: center;
    .cart-product__title {
        font-size: 16px;
        line-height: 24px;
    }
    .cart-product__subtitle {
        font-size: 14px;
        line-height: 24px;
    }
}

.prices-product-cart {
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
    flex-direction: column;
}

.cart-guide {
    @include brandon-medium;
    color: $grey-border;
    text-transform: uppercase;
    margin-right: 24px;
    margin-bottom: 0;

    &.first {
        &::before {
            content: "1";
            margin-right: 4px;
        }
    }
    &.second {
        &::before {
            content: "2";
            margin-right: 4px;
        }
    }
    &.third {
        &::before {
            content: "3";
            margin-right: 4px;
        }
    }

    &.active {
        color: $black;
        font-weight: bold;
        border-bottom: 2px solid $black;
    }
    
    &.done {
        color: $black;
        font-weight: bold;
        position: relative;
        padding-left: 18px;

        &::before {
            content: "";
            display: block;
            position: absolute;
            top: 4px;
            left: 0;
            width: 13px;
            height: 8px;
            border-left: 2px solid $black;
            border-bottom: 2px solid $black;
            transform: rotate(-45deg);
        }
    }
}

.shareWishlistButton {
    text-align: left;
    cursor: pointer;
    text-transform: uppercase;
    display: flex;
    align-content: center;
    > * {
        color: $gold;
        margin-bottom: 0px;
    }
}

hr.share-wishlist {
    border-top: 1px solid $line-color;
}

.share-button-div {
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.share-button {
    width: 44px;
    height: 44px;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    &.whatsapp {
        background: $green-whatsapp;
    }
    &.mail {
        background: $gold;
    }
    &.facebook {
        background: $blue-facebook;
    }
    > a > img {
        width: 24px;
        margin-bottom: 2px;
    }
}

.reveal {
    &.wishlist {
        @include breakpoint(medium down) {
            margin: 0 auto;
            width: 90%;
            height: auto;
            min-height: auto;
            top: 20% !important;
        }
    }
}

.flex {
    display: flex;
}

.reveal {
    border-radius: 4px;
}

.popupShareTitle {
    text-align: center;
    font-size: 23px;
}

.popupShareLink {
    border: 1px solid $line-color;
    padding: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border-right: none;
}

.popupShareLinkButton {
    display: flex;
    justify-content: center;
    align-items: center;
    background: $gold;
    color: $white;
}

.cart-main-price {
    @include brandon-medium;
}

.taxable-price {
    color: $grey-border;
    margin-right: 8px;
    &.linethrough {
        text-decoration: line-through;
    }
}
.cart-percentage {
    color: $gold;
    @include brandon-bold;
}

.burn-coupon-code {
    background: $light-grey;
    padding: 16px;
    border-radius: 8px;
}

.type-of-payment {
    img.payment {
        margin-right: 10px;
        :not(last-child) {
            margin-right: 0;
        }
        @include breakpoint(medium down) {
            margin-bottom: 10px;
        }
    }
}

//checkout feature

.checkout-feature {
    &.title {
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 0;
        text-transform: uppercase;
        @include brandon-medium;
    }

    &.list {
        font-size: 14px;
        line-height: 24px;

        &::before {
            content: url(/skins/shopopificio/images/icons/check-black.svg);
            display: inline-block;
            width: 10px;
            height: 10px;
            margin-right: 8px;
        }
    }
}
