*,
body {
    @include brandon-regular;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    color: $black;
    text-decoration: none;
    list-style-type: none;

    &:focus {
        outline: none;
    }
}

b, strong {
    font-weight: normal !important;
    @include brandon-medium;
}

.gold {
    color: $gold;
}

.white {
    color: $white;
}
//min width header
.min-width-900 {
    min-width: 900px;
}

.margin-header {
    @media screen and (max-width: 39.9375em) {
        margin-top: 0px;
    }
    @media screen and (min-width: 40em) {
        margin-top: 0px;
    }
}

.transition-in {
    opacity: 1 !important;
    transition: 1s;
}

.transition-out {
    opacity: 0 !important;
    transition: 1s;
}

.upper {
    text-transform: uppercase;
}

.body-container {
    @include breakpoint(medium down) {
        margin-top: 0px;
    }
}

.font-14 {
    font-size: 14px;
}

.font-12 {
    font-size: 12px;
}

.error {
    border-color: #cc4b37 !important;
    background-color: #f9ecea;
}

.error + label {
    text-transform: initial !important;
    color: $red !important;

    a {
        color: $red !important;
    }
}

:not([type=radio]):not([type=checkbox]).error + label {
    margin: 16px 0;
}

.error-password {
    color: white;
    background-color: $red;
}

.cms-wrapper {
    u {
        text-decoration: underline;
    }

    p{
        font-size:18px;
    }
}

.text-white {
    color: $white;
}

.text-left {
    text-align: left;
}

.text-shadow-none {
    text-shadow: none;
}

.row {
    max-width: 80rem;
}

.uppercase {
    text-transform: uppercase;
}

.flex {
    display: flex;
}

.direction-column {
    flex-direction: column;
}

.direction-column-tablet {
    @include breakpoint(medium down) {
        flex-direction: column;
    }
}

.direction-row {
    flex-direction: row;
}

.align-start {
    align-items: flex-start;
}

.justify-start {
    justify-content: flex-start;
}

.align-center {
    align-items: center;
}

.justify-center {
    justify-content: center;
}

.align-end {
    align-items: flex-end;
}

.justify-end {
    justify-content: flex-end;
}

.align-self-start {
    align-self: flex-start;
}

.align-self-center {
    align-self: center;
}

.align-self-end {
    align-self: flex-end;
}

.justify-space-between {
    justify-content: space-between;
}

.align-items-center {
    align-items: center;
}

.align-items-flex-end {
    align-items: flex-end;
}

.align-items-start {
    align-items: flex-start;
}

.align-items-stretch {
    align-items: stretch;
}

.justify-content-center {
    justify-content: center;
}

.flex-grow {
    flex-grow: 1;
}

.flex-wrap {
    flex-wrap: wrap;
}

//arrow

.arrow-link,
.arrow-link-left {
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 14px;
    position: relative;
    display: inline-block;
}

.arrow-link {
    &:after {
        content: ">";
        margin-left: 10px;
        right: -16px;
        top: 25px;
        font-size: 18px;
        color: $gold;

        &.left {
            content: "<";
        }
    }
}

:after {
    box-sizing: border-box;
}

.arrow-link-left:before {
    content: "<";
    margin-right: 10px;
    right: -16px;
    top: 25px;
    font-size: 18px;
    color: $gold;
}
:before {
    box-sizing: border-box;
}

button {
    padding: 0;
    border: none;
    font: inherit;
    color: inherit;
    background-color: transparent;
    cursor: pointer;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    @include caslon;
    line-height: normal;
    margin: 0;
}

p {
    font-size: 16px;
    margin-bottom: 10px;
}

.breadcrumb {
    font-size: 14px;
    text-transform: uppercase;

    li {
        display: inline;
    }

    li.after:after {
        content: ":";
        white-space: pre;
    }
}

ul li {
    font-size: 16px;
    padding-bottom: 5px;
}

p.first-p {
    font-size: 20px;
    font-family: "Playfair Display", serif;
    font-weight: normal;
    line-height: normal;
    font-style: italic;
    letter-spacing: 0px;
}

// position relative
.relative {
    position: relative;
}

.absolute {
    position: absolute;
}

//section
.margin-section {
    margin: 50px 0 50px;
}

.padding-page-section {
    padding-top: 30px;
}

//space

.margin-0 {
    margin: 0;
}

.margin-0-tablet {
    @include breakpoint(medium down) {
        margin: 0 !important;
    }
}
.margin-0-mobile {
    @include breakpoint(small down) {
        margin: 0 !important;
    }
}

.margin-0-auto {
    margin: 0 auto;
}

.margin-top-bottom-10 {
    margin: 10px 0px;
}

.margin-right-5 {
    margin-right: 5px;
}
.margin-right-10 {
    margin-right: 10px;
}
.margin-right-20 {
    margin-right: 20px;
}
.margin-right-30 {
    margin-right: 30px;
}
.margin-right-10-tablet {
    @include breakpoint(medium down) {
        margin-right: 10px;
    }
}

.margin-left-5 {
    margin-left: 5px;
}
.margin-left-10 {
    margin-left: 10px;
}
.margin-top-15 {
    margin-top: 15px;
}

.margin-top-20 {
    margin-top: 20px;
}
.margin-top-30 {
    margin-top: 30px;
}

.margin-top-40 {
    margin-top: 40px;
}

.margin-top-50 {
    margin-top: 50px;
}

.margin-bottom-0 {
    margin-bottom: 0;
}
.margin-bottom-15 {
    margin-bottom: 15px;
}
.margin-bottom-20 {
    margin-bottom: 20px;
}
.margin-bottom-30 {
    margin-bottom: 30px;
}
.margin-bottom-40 {
    margin-bottom: 40px;
}
.margin-bottom-50 {
    margin-bottom: 50px;
}
.margin-bottom-60 {
    margin-bottom: 60px;
}

.margin-0 {
    margin: 0px;
}

.margin-top-20-mobile {
    @include breakpoint(small down) {
        margin-top: 20px;
    }
}

.margin-top-10-tablet {
    @include breakpoint(medium down) {
        margin-top: 10px;
    }
}

.padding-0 {
    padding: 0px;
}

.padding-section {
    padding: 50px 0 50px;
}

.padding-15 {
    padding: 15px;
}
.padding-17 {
    padding: 17px;
}

.padding-top-15 {
    padding-top: 15px;
}

.padding-top-20 {
    padding-top: 20px;
}
.padding-top-30 {
    padding-top: 30px;
}

.padding-bottom-0 {
    padding-bottom: 0px;
}
.padding-top-1 {
    padding-top: 1px;
}
.padding-bottom-10 {
    padding-bottom: 10px;
}
.padding-bottom-15 {
    padding-bottom: 15px;
}
.padding-bottom-100 {
    padding-bottom: 100px;
}

.padding-left-right-10 {
    padding: 0px 10px;
}

.padding-s {
    padding: 24px;
}

.padding-m {
    padding: 32px;
}

.padding-60 {
    padding: 60px;
}

.padding-10-medium {
    @include breakpoint(medium down) {
        padding: 10px;
    }
}
.ph-m {
    padding-left: 32px;
    padding-right: 32px;
}

.list-unstyled {
    list-style: none;
}

.relative {
    position: relative;
}

.txt-left {
    text-align: left;
}
.txt-center {
    text-align: center;
}
.txt-right {
    text-align: right;
}

.txt-medium {
    @include brandon-medium;
}

.width-100 {
    width: 100% !important;
}

.border {
    border: 1px solid $line-color;
}

.border-bottom {
    border-bottom: 1px solid $line-color;
}

.border-gold {
    border: 1px solid $gold;
}

.border-right {
    border-right: 1px solid $gold;

    @include breakpoint(small down) {
        border-right: 0px;
    }

    @include breakpoint(medium down) {
        border-right: 0px;
    }
}

.inline-block {
    display: inline-block;
}

.padding-right-0 {
    padding-right: 0 !important;
}

.overflow-hidden {
    overflow: hidden;
}

.text-capitalize {
    text-transform: capitalize;
}

// spacing
.margin-bottom-10 {
    margin-bottom: 10px;
}

.margin-bottom-20 {
    margin-bottom: 20px;
}

.margin-bottom-30 {
    margin-bottom: 30px;
}

.margin-bottom-50 {
    margin-bottom: 50px;
}
.margin-top-10 {
    margin-top: 10px;
}

.margin-top-25 {
    margin-top: 25px;
}

.margin-top-40 {
    margin-top: 40px;
}

.margin-40-vert {
    margin: 40px 0;
}

.padding-top-50 {
    padding-top: 50px;
}

.padding-bottom-50 {
    padding-bottom: 50px;
}

//blog
.smooting-corners {
    border-radius: 0px 50px;
}

.subscribe-home-blog:nth-child(odd) img {
    border-radius: 0px 50px;
    width: 100%;
}

.subscribe-home-blog:nth-child(even) img {
    border-radius: 50px 0px;
    width: 100%;
}

// titolo e sottotitolo

.title-text-centered {
    justify-content: center;
    text-align: center;

    h1 {
        font-size: 40px;
        text-align: center;

        &.font-30 {
            font-size: 30px;
        }
    }

    h2 {
        font-size: 30px;
        text-align: center;
    }

    p {
        padding: 27px 0px 35px 0px;
        line-height: 1.7;
    }
}

//text
.txt-bold {
    font-weight: bolder;
}

.txt-gold {
    color: $gold;
}

.txt-light {
    opacity: .7;
}

.txt-nowrap {
    white-space: nowrap;
}

.medium-paragraph {
    @include brandon-medium;
}

.title-text-centered p br {
    @include breakpoint(small down) {
        display: none;
    }
    @include breakpoint(medium down) {
        display: none;
    }
}

//copertine
.home-2col-bg {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    padding: 145px 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    text-align: center;
    position: relative;
    @include breakpoint(small down) {
        padding: 20px 15px 30px;
    }
}

//background
.bg-light-grey {
    background-color: $light-grey;
}

.bg-dark-grey {
    background-color: $dark-grey;
}

.bg-gold {
    background-color: $gold;
}

.bg-gradient {
    background: #f7f7f7; /* Old browsers */
    background: -moz-linear-gradient(top, #f7f7f7 0%, #f7f7f7 43%, #ffffff 43%, #ffffff 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(
        top,
        #f7f7f7 0%,
        #f7f7f7 43%,
        #ffffff 43%,
        #ffffff 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
        to bottom,
        #f7f7f7 0%,
        #f7f7f7 43%,
        #ffffff 43%,
        #ffffff 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f7f7f7', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */

    @include breakpoint(medium down) {
        background: -moz-linear-gradient(top, #f7f7f7 0%, #f7f7f7 24%, #ffffff 24%, #ffffff 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(
            top,
            #f7f7f7 0%,
            #f7f7f7 24%,
            #ffffff 24%,
            #ffffff 100%
        ); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(
            to bottom,
            #f7f7f7 0%,
            #f7f7f7 24%,
            #ffffff 24%,
            #ffffff 100%
        ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    }

    @include breakpoint(small down) {
        background: -moz-linear-gradient(top, #f7f7f7 0%, #f7f7f7 16%, #ffffff 16%, #ffffff 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(
            top,
            #f7f7f7 0%,
            #f7f7f7 16%,
            #ffffff 16%,
            #ffffff 100%
        ); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(
            to bottom,
            #f7f7f7 0%,
            #f7f7f7 16%,
            #ffffff 16%,
            #ffffff 100%
        ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    }
}

//small divisor

.line-100 {
    width: 70px;
    border-bottom: none;
    border-left: none;
    border-right: none;
    border-top: 1px solid;
    border-color: $gold;
}
.line-150 {
    width: 150px;
    border-bottom: none;
    border-left: none;
    border-right: none;
    border-top: 1px solid;
    border-color: $gold;
}

.line-200 {
    border-bottom: none;
    border-left: none;
    border-right: none;
    border-top: 1px solid;
    border-color: #b79962;
    margin: 10px 0px 20px 0px;
}

.line-full {
    width: 100%;
    border-bottom: none;
    border-left: none;
    border-right: none;
    border-top: 1px solid;
    border-color: $dark-grey;
}

// fix menu ancore

.cms-wrapper .anchor {
    top: -160px;
    left: 0;
    position: absolute;
    font-size: 0;
}

//embed video Youtube responsive

.video-container {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
}

.video-container iframe,
.video-container object,
.video-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

//padding pagine archivio
.padding-wrapper {
    padding: 10px 0 10px;
}

.search-result {
    font-size: 40px;
    color: $black;
    margin-bottom: 0px;
}

.main-label {
    @include brandon-medium;
    font-size: 18px;

    &.popup-cart {
        font-size: 16px;
        margin-bottom: 4px;
    }
}

.height-100 {
    height: 100% !important;
}

.red-error {
    color: #c62828;
}

//image

.icons-24 {
    width: 24px;
}

.justify-content-center {
    justify-content: center;
}

.link {
    color: $gold;
    text-decoration: underline;

    &:visited {
        color: $gold-hover;
    }
}

[data-toggle-elem] {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    img {
        transition: transform .2s ease-in-out;
    }

    &.open img {
        transform: rotate3d(1, 0, 0, 180deg);
    }
}

.secondary-font {
    @include caslon;
}