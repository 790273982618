#ui-datepicker-div {
    background: $white;
    margin: 0 auto;
    padding: 24px;
    top: auto;
    width: 20vw;
    box-shadow: 0 12px 36px 16px $box-shadow;
    display: block;
    @include breakpoint(medium down) {
      width: 90vw;
    }

  .ui-icon.ui-icon-circle-triangle-w, .ui-icon.ui-icon-circle-triangle-e {
    &:before {
      content: " ";
      border-top: .5em solid transparent;
      border-bottom: .5em solid transparent;
      border-right: 0.75em solid $black;
      width: 0;
      height: 0;
      display: block;
      margin: 0 auto;
      }
  }

  .ui-icon.ui-icon-circle-triangle-w,
  .ui-icon.ui-icon-circle-triangle-e {
    cursor: pointer;
    box-sizing: content-box;
    height: 1em;
    padding: 0.5em 1.25em;
    position: absolute;
    top: 8px;
    width: 1em;
    }
    .ui-icon.ui-icon-circle-triangle-w {
      left: 0;
      padding-right: 1.25em;
    }
    .ui-icon.ui-icon-circle-triangle-e {
      right: 0;
      padding-left: 1.25em;
      &:before {
        border-left: 0.75em solid $black;
        border-right: 0;
      }
    }
    .ui-datepicker-title {
      margin-top: 0.75em;
      position: relative;
      text-align: center;
      .ui-datepicker-month, .ui-datepicker-year {
        display: inline-block;
        @include brandon-regular;
        margin-left: 0.25em;
        margin-right: 0.25em;
        font-size: 24px;
        line-height: 32px;
        }
        .ui-datepicker-year {
          color: $grey-border;
        }
    }

    .ui-datepicker-calendar {
      border-collapse: collapse;
      border-spacing: 0;
      font-size: inherit;
      margin-bottom: 0.5em;
      margin-top: 0.75em;
      table-layout: fixed;
      text-align: center;
      width: 100%;

      td {
        border: 1px solid transparent;
        padding: 0.3125em 0;
        &:hover {
          background-color: #a5864d62;
        }
        &.ui-datepicker-current-day {
          background-color: $gold;
          a {
            color: $white;
          }
        }
        &.ui-datepicker-today {
          border: 2px solid $gold;
        }
      }
    }
  }