.main-menu {
    ul {
        list-style: none;
    }

    > ul > li {
        display: inline-block;
        margin-right: -7px;

        > a {
            @include brandon-medium;
            font-size: 14px;
            font-style: normal;
            padding: 0 15px 5px;
            display: inline;
            position: relative;
            z-index: 11;
            text-transform: uppercase;

            &:hover::after {
                content: "";
                opacity: 1;
                position: absolute;
                left: 0;
                right: 0;
                bottom: -2px;
                height: 3px;
                background-color: $gold;
            }
        }

        &:hover {
            /*
      a::after {
        opacity: 1;
      }
      */

            .secondary-menu {
                display: block;
            }
        }
    }

    .decor-submenu-item {
        @include brandon-medium;
        font-size: 14px;
        font-style: normal;
        padding: 0 15px 5px;
        display: inline-block;
        position: relative;
        z-index: 11;
        text-transform: uppercase;

        &:hover::after {
            content: "";
            opacity: 1;
            position: absolute;
            left: 0;
            right: 0;
            bottom: -2px;
            height: 3px;
            background-color: $gold;
        }
    }
}

.secondary-menu {
    display: none;
    position: absolute;
    background-color: $white;
    left: 0;
    right: 0;
    max-width: 1170px;
    margin: 5px auto;
    /* padding: 42px; */
    z-index: 10;

    &.small {
        max-width: 290px;
        left: 617px;
    }

    ul li {
        text-align: left;
    }

    .column-block {
        margin: 0;

        .content-img {
            width: 192.8px;
            height: 192.9px;
            overflow: hidden;

            div.img {
                height: inherit;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: 50%;
                transition: all 0.3s ease-out;

                &.la-storia {
                    background-image: url("/skins/current-skin/images/menu/our-story.jpg");
                }
                &.interior-designer {
                    background-image: url("/skins/current-skin/images/menu/interior-designer.jpg");
                }
                &.le-boutique {
                    background-image: url("/skins/current-skin/images/menu/boutiques.jpg");
                }
                &.exclusive-brands-torino {
                    background-image: url("/skins/current-skin/images/menu/exclusive-brands-torino.png");
                }
            }
        }

        a:hover {
            div.img {
                transform: scale(1.1);
            }
        }

        h4 {
            @include brandon-regular;
            text-align: left;
            margin-top: 16px;
        }
    }
}

.menu-side-img {
    &.cuscini {
        width: 100%;
        height: 77%;
    }

    a {
        display: block;
    }

    span {
        display: block;
        font-size: 16px;
        margin: 12px 0 5px;
        text-align: left;
    }
}

.menu-big-img {
    text-align: center;

    a {
        display: block;
        margin-bottom: 30px;
    }

    span {
        @include caslon;
        display: block;
        font-size: 18px;
        letter-spacing: 0.01em;
        margin: -6px 0 12px;
    }
}

.secondary-menu__title,
.secondary-menu__title a {
    @include brandon-medium;
    font-size: 18px;
    margin-bottom: 15px;
    text-align: left;
}

//menu mobile

#offCanvasMenu {
    > div {
        /*padding: 129px 45px 45px; */
        padding: 20%;
        /*height: 85%;*/
        background-color: $light-grey;
        @include breakpoint(small down) {
            padding: 35px 37px 90px;
        }
    }

    .off-menu-image {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        img {
            width: 24px;
            cursor: pointer;
            transition: all 150ms ease;
            &:hover {
                opacity: 0.7;
            }
        }
    }

    .off-menu-text {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        animation-name: mobile-menu-fade;
        animation-duration: 0.7s;
        animation-fill-mode: forwards;

        &.js-submenu {
            display: none;
        }

        &.js-desktop-menu {
            display: flex;
            @include breakpoint(small down) {
                display: none;
            }
        }

        &.js-submenu-open {
            display: flex;
        }
    }

    ul {
        padding: 0;
        margin-top: 20px;
    }

    li {
        list-style: none;
        margin-bottom: 0px;

        a {
            /* border-bottom: 1px solid $gold; */
            font-size: 14px;
            display: block;
            /* padding: 4px 0 2px;*/
            margin-top: -1px;
            opacity: 0.6;
            /*margin-bottom: 17px;*/
            transition: opacity 150ms ease;
            text-transform: uppercase;
            &.opipromo {
                text-transform: initial;
                color: $gold;
            }
            @include breakpoint(small down) {
                padding: 0;
                opacity: 1;
            }
            &:hover {
                opacity: 1;
            }
        }
    }

    .off-canvas.is-transition-overlap.is-open {
        box-shadow: 0 0 10px hsla(0, 0%, 1%, 0.2);
    }

    .js-mobile-menu-back {
        display: none;
    }

    .js-open-submenu {
        position: relative;

        &::after {
            content: url(/skins/current-skin/images/icons/arrow-right.svg);
            width: 7px;
            height: auto;
            position: absolute;
            right: 0;
            top: 1px;
        }
    }

    .js-mobile-menu.off-menu-text {
        ul li {
            border-top: 1px solid $dark-grey;
            border-bottom: 1px solid $dark-grey;
            margin-top: -1px;
            padding: 12px 0px;
        }

        & li.no-border-top {
            border-top: none;
        }
    }

    .js-submenu.off-menu-text.js-submenu-open {
        ul li {
            border-top: 1px solid $dark-grey;
            border-bottom: 1px solid $dark-grey;
            margin-top: -1px;
            padding: 12px 0px;
        }
    }

    .js-mobile-menu {
        display: none;
        @include breakpoint(medium down) {
            display: flex;
        }
    }
}

.header-main__flex {
    display: flex;
    justify-content: center;
}

.header-main__side {
    align-self: center;
    width: 100px;
    display: flex;

    &.-right {
        text-align: right;
        width: 100%;
        justify-content: flex-end;
        @include breakpoint(medium down) {
            width: auto;
        }
    }
}

.header-utilities__cart {
    img {
        position: relative;
        top: -1px;
        &.wishlist {
            top: 0px;
        }
    }
}

.header-utilities__cart.inline-block .full-cart {
    top: -3px;

    &.wishlist {
        top: -2px;
    }
}

.av-search {
    cursor: pointer;
}

.header-utilities__space {
    margin: 0 3px;
    display: inline-block;

    .img-wishlist {
        margin: 0 5px;
    }

    .img-search {
        margin-left: 5px;
    }
}

.header-main__center {
    text-align: center;
}

.header-main__logo {
    width: 102px;
    padding: 5px 0px;

    @include breakpoint(small down) {
        width: 90px;
        padding: 0px;
    }
}

.header-utilities__language-dropdown {
    margin-right: 28px;
    position: relative;
    cursor: pointer;

    span {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        &::after {
            content: "";
            background-image: url(/skins/current-skin/images/icons/arrow-down.svg);
            background-repeat: no-repeat;
            width: 12px;
            height: 6px;
            margin-left: 8px;
        }
    }

    ul {
        display: none;
        position: absolute;
        list-style: none;
        background-color: $light-grey;
        border: 1px solid $dark-grey;
        width: 120px;
        text-align: center;
        top: 23px;
        left: -25px;
        padding: 5px 0 5px;
    }

    &:hover {
        ul {
            display: block;
        }
    }
}

.header-utilities__language-dropdown.login a.login-header {
    &::after {
        content: "";
        background-image: url(/skins/current-skin/images/icons/arrow-down.svg);
        background-repeat: no-repeat;
        width: 26px;
        height: 6px;
        position: absolute;
        right: 0;
        top: 10px;
        left: 109%;
    }
}

.off-canvas.is-transition-overlap.is-open {
    box-shadow: 0 0 10px hsla(0, 0%, 4%, 0.2);
}

.menu-icon-left {
    margin-right: 5px;
}

.off-canvas {
    background-color: $light-grey;
}

/* MEGA MENU POSITIONING STYLES
–––––––––––––––––––––––––––––––––––––––––––––––––– 
.top-bar-section .has-dropdown {
  position: static;
  a {
   position: relative;
   }
}
.top-bar-section .has-dropdown.moved {
  position: static;
  > a {
    &:after {
      display: block;
    }
  }
}
*/

/* DROPDOWN GENERAL STYLES
–––––––––––––––––––––––––––––––––––––––––––––––––– 

.m-menu {
  border-top: 1px solid #4E4E4E;
  a {
    display: block;
    opacity: .5;
    transition: opacity .3s ease;
    &:hover {
      opacity: 1;
    }
  }
}
*/

/* TOP LINKS INSIDE DROPDOWN STYLES
–––––––––––––––––––––––––––––––––––––––––––––––––– 

.column li {
  &:not(:first-child) a {
    border-top: 1px solid #262626;
  }
  &:not(:last-child) a {
    border-bottom: 1px solid #4E4E4E;
  }
  a {
    &:hover {
      i {
         right: 3px;
      }
    }
  }
}
*/

/* BOTTOM LINKS INSIDE FIRST DROPDOWN STYLES
–––––––––––––––––––––––––––––––––––––––––––––––––– 
.intro {
  .icon-play {
    position: absolute;
    color: rgba(0, 0, 0, 0.4);
    font-size: 2.5em;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: color .3s ease;
  }
  &:hover {
    .icon-play {
      color: rgba(0, 0, 0, 0.7);
    }
  }
}

*/
/* TARGET DIV INSIDE SECOND DROPDOWN STYLES
–––––––––––––––––––––––––––––––––––––––––––––––––– 
.column > img { 
  margin: 1em 0;
}

.info-wrap {
  h3 {
    width: 50%;
    padding-bottom: 5px;
    border-bottom: 1px solid #4E4E4E;  
  }
}

.info {
  display: none;
}

.info-visible {
  display: block;
}

.dt {
  margin-top: 50px;
  text-align: center;
  font-size: 2em;
  padding: 0 20px;
}

.p {
  color: black;
  text-align: center;
  position: fixed;
  bottom: 0;
  width: 100%;
}
*/

//megamenu
.menu-columns {
    padding: 42px;
    border-right: 1px solid $dark-grey-second;
    min-height: 350px;
    &.first {
        background-color: $white;
        border: 1px solid $light-grey;
    }
    &.second {
        background-color: $light-grey;
    }
    &.third {
        background-color: $dark-grey-second;
    }
    &.fourth {
        background-color: $dark-grey-third;
    }
    &.image {
        background-color: $dark-grey-third;
        border-right: none;
    }
}

.logout {
    text-transform: uppercase;
    font-size: 14px;
    padding: 0px;
}
