@import url("//hello.myfonts.net/count/2ac2dc");

@font-face {
  font-family: 'caslon';
  font-display: swap;
  src: url('/skins/opificio/fonts/caslon/2AC2DC_0_0.eot');
  src: url('/skins/opificio/fonts/caslon/2AC2DC_0_0.eot?#iefix') format('embedded-opentype'),
    url('/skins/opificio/fonts/caslon/2AC2DC_0_0.woff') format('woff'),
    url('/skins/opificio/fonts/caslon/2AC2DC_0_0.ttf') format('truetype');
}

@font-face {
  font-family: 'brandon-light';
  font-display: swap;
  src: url('/skins/opificio/fonts/brandon/BrandonGrotesque-Light.eot');
  src: url('/skins/opificio/fonts/brandon/BrandonGrotesque-Light.eot?#iefix') format('embedded-opentype'), 
    url('/skins/opificio/fonts/brandon/BrandonGrotesque-Light.woff2') format('woff2'), url('/skins/opificio/fonts/brandon/BrandonGrotesque-Light.woff') format('woff'), 
    url('/skins/opificio/fonts/brandon/BrandonGrotesque-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'brandon-regular';
  font-display: swap;
  src: url('/skins/opificio/fonts/brandon/BrandonGrotesque-Regular.eot');
  src: url('/skins/opificio/fonts/brandon/BrandonGrotesque-Regular.eot?#iefix') format('embedded-opentype'), 
  url('/skins/opificio/fonts/brandon/BrandonGrotesque-Regular.woff2') format('woff2'), url('/skins/opificio/fonts/brandon/BrandonGrotesque-Regular.woff') format('woff'), 
  url('/skins/opificio/fonts/brandon/BrandonGrotesque-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'brandon-medium';
  font-display: swap;
  src: url('/skins/opificio/fonts/brandon/BrandonGrotesque-Medium.eot');
  src: url('/skins/opificio/fonts/brandon/BrandonGrotesque-Medium.eot?#iefix') format('embedded-opentype'), 
  url('/skins/opificio/fonts/brandon/BrandonGrotesque-Medium.woff2') format('woff2'), url('/skins/opificio/fonts/brandon/BrandonGrotesque-Medium.woff') format('woff'), 
  url('/skins/opificio/fonts/brandon/BrandonGrotesque-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'brandon-bold';
  font-display: swap;
  src: url('/skins/opificio/fonts/brandon/BrandonGrotesque-Bold.eot');
  src: url('/skins/opificio/fonts/brandon/BrandonGrotesque-Bold.eot?#iefix') format('embedded-opentype'), 
  url('/skins/opificio/fonts/brandon/BrandonGrotesque-Bold.woff2') format('woff2'), url('/skins/opificio/fonts/brandon/BrandonGrotesque-Bold.woff') format('woff'), 
  url('/skins/opificio/fonts/brandon/BrandonGrotesque-Bold.ttf') format('truetype');
}



