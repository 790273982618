.product-item {
	position: relative;
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	&:hover {
		.product-item__img img + img {
			opacity: 1;
		}

		.product-item__img picture + picture {
			opacity: 1;
		}
	}
}

.add-to-wishlist-catalog,
.remove-from-wishlist-catalog {
	position: absolute;
	top: 10px;
	right: 10px;
	width: 30px;
	height: 30px;
	border-radius: 20px;
	z-index: 4;
	cursor: pointer;

	img {
		width: 22px;
	}
}

.close-popup {
	position: absolute;
	// right: 16px;
	// width: 16px;
	cursor: pointer;

	&.wishlist {
		right: 48px;
		top: 30px;
	}
}

.play-vimeo {
	position: absolute;
	width: 40%;
	left: 30%;
	height: 30%;
	top: 33%;
	border: none !important;
	padding: 0 !important;
}

.product-item__img {
	position: relative;
	margin: 0 0 10px;
	display: flex;
	justify-content: center;

	img + img {
		position: absolute;
		// left: 50%;
		top: 0;
		right: 0;
		bottom: 0;
		opacity: 0;
		z-index: 1;
		// transform: translateX(-50%);
	}

	picture + picture {
		position: absolute;
		// left: 50%;
		top: 0;
		right: 0;
		bottom: 0;
		opacity: 0;
		z-index: 1;
		// transform: translateX(-50%);
	}
}

.product-item__brand {
	@include caslon;
	font-size: 24px;
	margin-bottom: 2px;
	@include breakpoint(small down) {
		font-size: 18px;
	}
}

.product-item__descr {
	@include brandon-bold;
	text-transform: uppercase;
	font-size: 10px;
	letter-spacing: 0.05em;
}

.product-item__price {
	/*display: block;*/
	font-size: 20px;
	line-height: normal;
	margin-top: 10px;
	padding-bottom: 5px;
	@include brandon-medium;
}

.product-item__retail-price {
	font-size: 10px;
	text-transform: uppercase;
	font-weight: bold;
	letter-spacing: 0.05em;
	color: $text-grey;
	margin-top: -1px;
	display: block;
}

#product-slider-best {
	.product-item__price-block {
		margin: 5px 0 15px;
		width: 500px;
		border-top: 1px solid $line-color;

		span.discount {
			color: $grey-border;
			font-size: 20px;
			margin-left: 10px;
			margin-top: 10px;
			padding-bottom: 5px;
			text-transform: inherit;

			&.linedthrough {
				text-decoration: line-through;
			}
		}
	}
	.product-item {
		&:hover {
			.product-item__price-block {
				width: 500px;
				border-top: 1px solid $gold;
			}
		}
		&:active {
			.product-item__price-block {
				width: 500px;
				border-top: 1px solid $gold;
			}
		}
	}
}

.product-item__price-block {
	margin: 5px 0 15px;
	border-top: 1px solid $line-color;

	span.discount {
		color: $grey-border;
		font-size: 20px;
		margin-left: 10px;
		margin-top: 10px;
		padding-bottom: 5px;
		text-transform: inherit;

		&.linedthrough {
			text-decoration: line-through;
		}
	}
}

.product-item {
	&:hover {
		.product-item__price-block {
			border-top: 1px solid $gold;
		}
	}
	&:active {
		.product-item__price-block {
			border-top: 1px solid $gold;
		}
	}
}

.product-item__link {
	position: absolute;
	bottom: 0;
	left: 0;
	top: 0;
	right: 0;
	z-index: 2;
}

.product-item__table {
	@include breakpoint(small down) {
		margin: 10px 0px;
	}
}

.product-item__description {
	border-top: 1px solid $line-color;
	padding-top: 15px;
}

.product-code {
	font-size: 14px;
}

//catalog griglia

.prompt-delivery {
	// width: 300px;
	border: 1px solid $gold;
	border-radius: 8px;
	margin: 5px auto 0;
	padding: 4px;

	&.expanded {
		margin: 5px 0px;
	}
	p {
		margin-bottom: 0px;
		text-transform: uppercase;
		font-size: 14px;
	}

	&.size {
		border: none;
		margin: 10px 0px 0;

		p {
			color: $gold;
			text-align: center;
		}
	}
}

span.prompt-delivery {
	// width: 300px;
	border-radius: 8px;
	color: $gold;
	text-transform: uppercase;
	font-weight: 600;
	font-size: 14px;
	margin-left: 5px;
}

.rate-price {
	.save {
		text-align: left;
		font-size: 18px;
		margin-top: 4px;
		color: $grey-border;
		text-transform: inherit;
		letter-spacing: 0.3px;
	}
	.price {
		color: $grey-border;
		font-size: 18px;
		margin-left: 10px;
	}
}

.product-item.relative {
	.add-to-wishlist-catalog {
		display: none;
		@include breakpoint(medium down) {
			display: block;
		}
	}
	&:hover {
		.add-to-wishlist-catalog {
			display: block;
		}
	}
}

.business-banner {
	border: 1px solid $gold;
	background-color: $light-grey;
	padding: 24px;
	margin: 20px 0;
}
