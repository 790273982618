.footer-logo {
    text-align: center;

    img {
        width: 100px;
    }

    p {
        font-size: 18px;
        @include caslon;
        color: $black;
    }
}

.footer-links {
    text-align: center;
    padding: 28px 0 25px;
    background-color: $light-grey;
    border-bottom: 2px solid $gold;
    @include breakpoint(small down) {
        padding: 5px 0 35px;
    }

    h4 {
        @include brandon-bold;
        letter-spacing: 0.15em;
        font-size: 13px;
        text-transform: uppercase;
        margin-bottom: 15px;
        @include breakpoint(small down) {
            margin: 30px 0 5px;
        }
    }

    ul {
        list-style: none;
    }

    a {
        @include transition-basic;
        font-size: 12px;
        text-transform: uppercase;
        letter-spacing: 0.03em;

        &:hover {
            color: $gold;
        }
    }
}

.footer-icons {
    background-color: $black;
    text-align: center;
    padding: 20px 0 24px;
    @include breakpoint(small down) {
        padding: 0 0 40px;
    }

    span {
        color: white;
        display: block;
        text-transform: uppercase;
        font-size: 11px;
        letter-spacing: 0.15em;
        @include breakpoint(medium down) {
            font-size: 9px;
        }
    }

    .footer-icons__title {
        @include brandon-bold;
        font-size: 13px;
    }

    img {
        width: 35px;
        margin-bottom: 5px;
        @include breakpoint(small down) {
            margin: 35px 0 5px;
        }
    }

    h5 {
        color: white;
        font-size: 19px;
        margin: 12px 0 16px;
    }
}

.footer-payments {
    text-align: center;
    border-bottom: 1px solid $line-color;
    padding: 30px 0 32px;
    @include breakpoint(medium down) {
        padding: 30px 0 28px;
    }

    h4 {
        @include brandon-bold;
        letter-spacing: 0.15em;
        font-size: 13px;
        text-transform: uppercase;
        margin-bottom: 25px;
    }
}

.footer-payments__logos {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    filter: grayscale(100%);

    img {
        margin: 0 10px;
        @include breakpoint(medium down) {
            margin: 0 8px 10px;
        }
    }
}

.footer-social {
    background-color: $black;
    padding: 28px 0 30px;
    text-align: center;

    h4 {
        @include brandon-bold;
        letter-spacing: 0.15em;
        font-size: 13px;
        text-transform: uppercase;
        margin-bottom: 25px;
        color: white;
    }

    img {
        width: auto;
        height: 25px;
        margin: 0 10px;
    }
}

.footer-social__flex {
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer-data {
    background-color: $white;
    padding: 11px 0 10px;

    h3 {
        color: $black;
        @include brandon-medium;
        text-align: left;
        text-transform: uppercase;
        color: $gold;
        font-size: 18px;
    }
    ul {
        margin-top: 20px;
    }

    li a {
        color: $black;
        text-align: left;
        font-size: 16px;
        line-height: 2em;
    }

    .divisore-footer {
        border: 0.7px solid #fff;
        margin-right: 5px;
    }
}

.footer-data-space {
    text-align: center;
    font-size: 12px;
    padding-top: 10px;
}

.footer-bottom {
    background-color: $white;
}

.cookie_policy {
    text-align: center;
    padding: 11px 0 10px;
    font-weight: 100;
    text-transform: uppercase;
    font-size: 12px;
    color: #fff;

    a {
        @include brandon-regular;
    }
}

.social-icons-footer {
    text-align: center;
}

.contact {
    img {
        margin-right: 10px;
    }
}

.footer-links-img {
    width: 40px;
    height: 40px;
    margin-right: 25px;
}

.footer-links-text {
    text-align: left;
    font-size: 16px;
}

.social-icons-footer img {
    margin-right: 15px;
}
