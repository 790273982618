.alert-image {
    height: 65px;
    margin-bottom: 20px;

    &.success {
        width: 60px;
    }
}

.alert-box {
    display: flex;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2000;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.3);

    .alert-container {
        @media screen and (max-width: 39.9375em) {
            width: 90%;
            margin-top: 10px;
        }

        /* Medium only */
        @media screen and (min-width: 40em) and (max-width: 64em) {
            width: 60%;
            margin-top: 60px;
        }

        /* Medium only */
        @media screen and (min-width: 64em) and (max-width: 85.375em) {
            width: 40%;
            margin-top: 60px;
        }
        width: 20%;
        /* box-shadow: 2px 2px 15px 1px #717171; */
        text-align: center;

        .close-button,
        .confirm-button {
            text-align: center;
            margin-top: 8px;
        }
    }

    .alert,
    .alert > p,
    .alert > span {
        @include caslon;
        font-size: 30px;
        /*padding: 30px 0;*/
        color: $black;
        line-height: 40px;
        animation-name: alert;
        animation-duration: 200ms;
        animation-timing-function: ease-in-out;
        animation-fill-mode: forwards;
        margin-top: 10px;
    }

    .alert > p {
        min-height: 40px;
    }

    .alert a {
        color: $gold;
    }

    /*
  .alert-message {
    @include caslon;
    font-size: 30x;
    animation-name: alert;
    animation-duration: 200ms;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    padding: 60px 0;
    color: $black;
  }
*/
    .alert-danger,
    .alert-success {
        background-color: $white;
        text-align: center;
        border: 1px solid $gold;
        padding: 30px;
        @include breakpoint(small down) {
            padding: 40px;
        }
    }
}

@keyframes alert {
    0% {
        transform: translateY(10px);
        opacity: 0;
    }
    100% {
        transform: translateY(0px);
        opacity: 1;
    }
}

.block-scroll {
    margin: 0;
    height: 100%;
    overflow: hidden;
}
